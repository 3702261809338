<template>
  <div
    ref="map-root"
    style="width: 100%; height: 100%"
    class="map map__wrapper"
  >
    <div ref="tooltip" class="map__tooltip">
      <div class="map__tooltip-content">
        <div class="map__tooltip-country">{{ countryName }}</div>
        <div class="">
          {{ $t('tooltip.tourists_number_total') }}: {{ touristsNumber }}
        </div>
      </div>
      <svg-icon name="tooltip_tail" class="map__tooltip-tail" ref="tail" />
    </div>
  </div>
</template>

<script>
import View from 'ol/View';
import Map from 'ol/Map';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { Fill, Stroke, Style } from 'ol/style';
import 'ol/ol.css';
import { mapGetters } from 'vuex';

export default {
  name: 'MapContainer',
  components: {},
  props: {
    country: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      selectionLayer: null,
      tooltip: null,
      tail: null,
      countryName: '',
      touristsNumber: 0,
      widthDelta: 170,
      heightDelta: 25,
      delta: 15,
    };
  },
  mounted() {
    this.tooltip = this.$refs.tooltip;
    this.tail = this.$refs.tail.$el;

    this.setMap();
    this.setSelectedLayer();

    if (this.getIsTouchDevice) {
      this.map.on('click', (evt) => {
        this.showFeatureInfo(this.map.getEventPixel(evt.originalEvent));
      });
    }
    this.map.on('pointermove', (evt) => {
      if (evt.dragging) {
        this.tooltip.style.display = 'none';
        return;
      }
      this.showFeatureInfo(this.map.getEventPixel(evt.originalEvent));
    });

    this.resizeMapOnMobyle();
  },

  watch: {
    country: {
      async handler() {
        this.selectionLayer.changed();
        // this.selectionLayer.getSource().clear();
        // this.selectionLayer.getSource().changed();
        // if (!!val.length) {
        //   this.map.getView().setZoom(2);
        // } else {
        //   this.map.getView().setZoom(0);
        // }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['getIsTouchDevice']),
    getVisitedCountry() {
      return new Style({
        stroke: new Stroke({
          color: 'rgb(0,117,255)',
          width: 1.5,
        }),
        fill: new Fill({
          color: 'rgba(0,117,255,0.5)',
        }),
      });
    },
    getNoVisitedCountry() {
      return new Style({
        stroke: new Stroke({
          color: '#709fd9',
          width: 1.5,
        }),
        fill: new Fill({
          color: 'rgba(0,117,255,0.10)',
        }),
      });
    },

    getLayer() {
      return new VectorTileLayer({
        source: new VectorTileSource({
          maxZoom: 15,
          format: new GeoJSON(),
          // url: 'https://raw.githubusercontent.com/openlayers/ol3/6838fdd4c94fe80f1a3c98ca92f84cf1454e232a/examples/data/geojson/countries.geojson',
          url: 'https://api.etr.devbazu.com/storage/additional/geo_data_of_countries.json',
        }),
        style: null,
      });
    },

    getMaxWidthEl() {
      return this.widthDelta + this.delta;
    },
  },

  methods: {
    setMap() {
      this.map = new Map({
        target: this.$refs['map-root'],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          this.getLayer,
        ],

        view: new View({
          zoom: 0,
          center: [0, 0],
          // constrainResolution: true,
        }),
      });
    },

    setSelectedLayer() {
      this.selectionLayer = new VectorTileLayer({
        map: this.map,
        renderMode: 'vector',
        source: this.getLayer.getSource(),
        declutter: true,
        renderBuffer: 700,
        style: (feature) => {
          if (this.country.find((el) => el.map_id === feature.getId())) {

            return !!this.country.find((el) => el.map_id === feature.getId())
              .number_of_tourists
              ? this.getVisitedCountry
              : this.getNoVisitedCountry;
          }
        },
      });
    },

    showFeatureInfo(pixel) {
      const [curX, curY] = pixel;
      this.tooltip.style.left = `${curX - this.getMaxWidthEl}px`;
      this.tooltip.style.top = `${curY - this.heightDelta}px`;
      this.tail.classList.remove(
        'map__tooltip-tail_left',
        'map__tooltip-tail_left-top',
        'map__tooltip-tail_right-top',
        'map__tooltip-tail_right-bottom',
        'map__tooltip-tail_left-bottom'
      );

      let isLeft = curX <= this.getMaxWidthEl,
        isTop = curY <= this.heightDelta,
        isBottom =
          curY + this.heightDelta >= this.$refs['map-root'].clientHeight;

      if (isLeft) {
        this.tooltip.style.left = `${curX + this.delta}px`;
        this.tail.classList.add('map__tooltip-tail_left');

        if (isTop) {
          this.tooltip.style.top = '0px';
          this.tail.classList.add('map__tooltip-tail_left-top');
        } else if (isBottom) {
          this.tooltip.style.top = `${
            this.$refs['map-root'].clientHeight - this.heightDelta * 2
          }px`;
          this.tail.classList.add('map__tooltip-tail_left-bottom');
        }
      } else if (isTop) {
        this.tooltip.style.top = '0px';
        this.tail.classList.add('map__tooltip-tail_right-top');
      } else if (isBottom) {
        this.tooltip.style.top = `${
          this.$refs['map-root'].clientHeight - this.heightDelta * 2
        }px`;
        this.tail.classList.add('map__tooltip-tail_right-bottom');
      }

      const feature = this.map.forEachFeatureAtPixel(pixel, (feature) => {
        return feature;
      });
      if (feature) {
        let curCountry = this.country.find(
          (el) => el.map_id === feature['id_']
        );

        this.countryName = curCountry.name;
        this.touristsNumber = curCountry.number_of_tourists;
        this.tooltip.style.display = 'block';
      } else {
        this.tooltip.style.display = 'none';
      }
    },

    resizeMapOnMobyle() {
      const container = document.querySelector('.main_container');
      const resizeObserver = new ResizeObserver((entries, observer) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            const contentBoxSize = Array.isArray(entry.contentBoxSize)
              ? entry.contentBoxSize[0]
              : entry.contentBoxSize;
            if (contentBoxSize.inlineSize < 435) {
              if (!!this.$refs['map-root']) {
                this.$refs['map-root'].style.width = '280px';
              }
            } else {
              if (!!this.$refs['map-root']) {
                this.$refs['map-root'].style.width = '100%';
              }
            }
          }
        }
      });

      resizeObserver.observe(container);
    },
  },
};
</script>
<style lang="sass" scoped>
.map__wrapper
  position: relative
  border: solid 1px $gray_light
.map__tooltip
  position: absolute
  z-index: 100
  padding: 3px 3px 3px 10px
  background: rgba(0,0,0, 0.5)
  width: 170px
  height: 50px
  border-radius: 4px
  display: none

.map__tooltip-content
  display: flex
  flex-direction: column
  justify-content: space-around
  font-size: 10px
  color: $white
  width: 100%
  height: 100%
.map__tooltip-country
  line-height: 12px

.map__tooltip-tail
  position: absolute
  width: 15px
  height: 15px
  top: 50%
  left: 100%
  transform: translateX(-4px) translateY(-50%) rotate(-90deg)
  opacity: 0.5

.map__tooltip-tail_right
  top: 50%
  left: 100%
  transform: translateX(-4px) translateY(-50%) rotate(-90deg)
.map__tooltip-tail_left
  top: 50%
  left: -11px
  transform: translateX(0) translateY(-50%) rotate(90deg)
.map__tooltip-tail_left-top
  top: 15%
  transform: translateX(0) translateY(0) rotate(90deg)
.map__tooltip-tail_right-top
  top: 15%
  transform: translateX(-4px) translateY(0) rotate(-90deg)
.map__tooltip-tail_right-bottom
  top: 55%
  transform: translateX(-4px) translateY(0) rotate(-90deg)
.map__tooltip-tail_left-bottom
  top: 55%
  transform: translateX(0) translateY(0) rotate(90deg)
</style>
