<template>
  <div class="statistic-table">
    <template>
      <div class="statistic-table__wrapper">
        <div class="statistic-table__greed statistic-table__header">
          <div
            v-for="item in tableHead"
            :key="item"
            class="statistic-table__header-item"
            :class="{
              'statistic-table__header-item_growth':
                item === 'growth_percentage',
              'statistic-table__sort_dwn': sort === 'dwn' && item === sortBy,
            }"
            @click="sortHandler(item)"
          >
            <!-- <div
              v-if="item === 'growth_percentage'"
              class="statistic-table__sort"
              :class="{ 'statistic-table__sort_dwn': sort === 'dwn' }"
              @click="sortHandler(item)"
            >
              %
              <svg-icon
                name="arrow-statistic"
                class="statistic-table__arrow-ico statistic-table__header-ico"
              />
            </div> -->
            <template v-if="item === 'name'">
              {{ $t('table.country_name') }}
            </template>
            <template v-else-if="item === 'growth_percentage'"> % </template>
            <template v-else-if="isStarCategoryHead(item)">
              {{ $t(`table.${item}`) }}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_83_1348)">
                <path d="M11.25 4.875H7.21875L6 1.125L4.78125 4.875H0.75L4.03125 7.125L2.76562 10.875L6 8.53125L9.23438 10.875L7.96875 7.125L11.25 4.875Z" fill="#EDC90D" stroke="#EDC90D" stroke-opacity="0.87" stroke-width="0.5" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_83_1348">
                <rect width="12" height="12" fill="white"/>
                </clipPath>
                </defs>
              </svg>

            </template>
            <template v-else>
              {{ $t(`table.${item}`) }}
            </template>
            <svg-icon
              name="arrow-statistic"
              class="statistic-table__arrow-ico statistic-table__header-ico"
            />
          </div>
        </div>
        <template v-if="isTable">
          <div
            class="statistic-table__content statistic-table__greed"
            v-for="item in getTableContent"
            :key="item.name"
          >
            <div
              v-for="headerItem in tableHead"
              :key="headerItem"
              class="statistic-table__content-item"
              :class="{
                'statistic-table__growth-item':
                  headerItem === 'growth_percentage',
              }"
            >
              <div class="statistic-table__header-title">
                <template v-if="headerItem === 'name'">
                  {{ $t('table.country_name') }}:
                </template>
                <template v-else-if="headerItem === 'growth_percentage'">
                  %:
                </template>
                <template v-else-if="isStarCategoryHead(headerItem)">
                  <star-rating
                    :rating="parseInt(headerItem.replace('star_category_', ''))"
                    :active-on-click="false"
                    :max-rating="parseInt(headerItem.replace('star_category_', ''))"
                    :star-size="14"
                    :fixed-points="1"
                    :show-rating="false"
                    :clearable="false"
                    :padding="4"
                    :border-width="2"
                    :inline="true"
                    :read-only="true"
                    border-color="#6B6B6B"
                    active-border-color="#FFB33B"
                    active-color="#FFB33B"
                    inactive-color="#fff"
                  />

                </template>
                <template v-else> {{ $t(`table.${headerItem}`) }}: </template>
              </div>
              <template v-if="headerItem === 'growth_percentage'">
                {{
                  !!+item[headerItem]
                    ? `${Math.floor(+item[headerItem] * 100) / 100}%`
                    : '-'
                }}
                <svg-icon
                  name="arrow-statistic"
                  class="statistic-table__arrow-ico"
                  :class="[
                    !!+item[headerItem] && +item[headerItem] > 0
                      ? 'grouwth'
                      : 'decrease',
                  ]"
                />
              </template>
              <template
                v-else-if="headerItem === 'number_of_tourists_in_percent'"
              >
                {{
                  !!+item[headerItem]
                    ? `${Math.floor(+item[headerItem] * 100) / 100}%`
                    : '-'
                }}
              </template>
              <template v-else>
                <slot :name="headerItem" v-bind:data="item">{{ !!item[headerItem] ? item[headerItem] : '-' }}</slot>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="statistic-table__no-data">
            <NoData />
          </div>
        </template>

        <div v-if="tableFooter" class="statistic-table__footer statistic-table__greed">
          <div class="statistic-table__footer-item">
            {{ $t('table.total_result') }}
          </div>
          <div
            class="statistic-table__footer-item"
            v-for="(item, i) in tableFooter"
            :key="i"
          >
            <slot :name="`tableFooter_${i}`" v-bind:data="tableFooter">{{ item }}</slot>
          </div>
        </div>
        <SimplePagination
          v-if="isTable"
          :paginationData="getPaginationData"
          @detectedPageValue="detectedPageValue"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimplePagination from '@/elements/SimplePagination.vue';
import NoData from '@/elements/NoData.vue';
import StarRating from 'vue-star-rating';

export default {
  components: {
    SimplePagination,
    NoData,
    StarRating,
  },
  props: {
    tableHead: {
      type: Array,
      default: () => [],
    },
    tableFooter: {
      type: Object,
      default: () => {},
    },
    table: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      sort: '',
      sortBy: '',
      curPage: 1,
      toPage: 15,
      perPage: 15,
    };
  },

  watch: {
    table: {
      handler() {
        this.toPage = 15;
        this.curPage = 1;
      },
    },
  },

  computed: {
    ...mapGetters(['getPageQty']),

    isTable() {
      return !!this.getTableContent?.length;
    },

    getTableContent() {
      return this.table?.items.slice(this.curPage - 1, this.toPage);
    },

    // getTableFooter() {
    //   return {
    //     tourists: this.table?.total_number_of_tourists,
    //     percent: !!this.table
    //       ? `${Math.floor(+this.table?.total_growth_percentage * 100) / 100}%`
    //       : '0%',
    //   };
    // },

    getTableHead() {
      return this.tableHead.map((el) => {
        if (el === 'name') {
          return 'country_name';
        } else {
          return el;
        }
      });
    },

    isGrowth() {
      return (
        !!this.table?.total_growth_percentage &&
        this.table?.total_growth_percentage > 0
      );
    },

    getPaginationData() {
      if (!!this.pagination) {
        return this.pagination;
      } else {
        return {
          from: this.curPage,
          to: this.toPage,
          total: this.table?.items.length,
          current_page: this.curPage,
          last_page: this.table?.items.length,
        };
      }
    },
  },

  methods: {
    detectedPageValue(page) {
      if (!!this.pagination) {
        this.$emit('tableHandler', page);
      } else {
        this.curPage = this.perPage * page + 1;
        this.toPage = this.perPage * (page + 1);
        if (this.toPage >= this.table?.items.length) {
          this.toPage = this.table?.items.length;
        }
      }
    },
    sortHandler(columns_name) {
      this.sortBy = columns_name;
      this.sort = this.sort === 'up' ? 'dwn' : 'up';
      this.$store.commit('SET_TABLE_SORT_DATA', {
        sort: { item: columns_name, type: this.sort },
      });
      this.$emit('tableHandler');
    },

    isStarCategoryHead(val) {
      const regex = new RegExp(/star_category_[0-9]/)
      return regex.test(val)
    },

    prepareStarValue() {
      return 2
    },
  },
};
</script>

<style lang="sass" scoped>
.statistic-table__wrapper
   margin-bottom: 15px
   height: 100%
.statistic-table__header
  background-color: $black
  color: $white
  font-size: 13px
  @include xs
    &.statistic-table__greed
      display: none
.statistic-table__header-item
  padding: 10px
  white-space: nowrap
  cursor: pointer
  &:last-child
    text-align: right
.statistic-table__header-item_growth
  text-align: center

.statistic-table__greed
  display: grid
  grid-template-columns: minmax(25rem, 3.5fr) minmax(15rem, 2fr) minmax(6.9rem, 1.5fr) minmax(18rem, 3fr)
  align-items: center
  gap: 5px
  @include xs
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: flex-start
    gap: 0
    // flex-direction: column
    // align-items: flex-start

.statistic-table__content
  border-bottom: 1px solid $gray
  font-size: 13px
  line-height: 15px
  height: 50px
  @include xs
    height: auto
    padding: 5px 10px
.statistic-table__content-item
  &:first-child
    padding: 0 10px 0 20px
    @include xs
      padding: 5px
  &:not(&:first-child,  &:last-child, .statistic-table__growth-item)
    padding: 0 20px 0 30px
    @include xs
      padding: 5px

  &:last-child
    text-align: right
    padding-right: 50px
    @include xs
      text-align: left
      padding: 5px

  @include xs
    width: 50%
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
  @include xxs
    width: 100%
    // padding: 10px
    // border: solid 1px red

.statistic-table__growth-item, .statistic-table__footer-growth-item
  text-align: right
  padding-right: 7px
  white-space: nowrap
  @include xs
    text-align: left
    padding: 10px

.statistic-table__footer
  background: #FAFAFA
  margin-top: 3px
  font-weight: bold
  font-size: 13px
  @include xs
    &.statistic-table__greed
      display: none
.statistic-table__footer-item
  &:first-child
    padding: 10px
  &:last-child
    text-align: right
    padding-right: 50px
  &:not(&:first-child, &:last-child, .statistic-table__footer-growth-item)
    padding: 10px 20px 10px 24px
.statistic-table__arrow-ico
  width: 12px
  height: 6px
  position: relative
  top: -3px
  @include xs
    top: 4px
.statistic-table__header-ico
  fill: $white
.statistic-table__no-data
  height: 82%
  @include s
    height: auto

.statistic-table__header-title
  display: none
  @include xs
    display: block
    white-space: nowrap
    font-weight: bold
    margin-bottom: 5px
    margin-right: 10px
// .statistic-table__sort
//   cursor: pointer

// .statistic-table__sort_up
//   .statistic-table__header-ico
.statistic-table__sort_dwn
  .statistic-table__header-ico
    transform: rotate(-180deg)
    top: -1px

.grouwth
  fill: $success
.decrease
  fill: #FF5552
  transform: rotate(-180deg)
</style>
