<template>
  <div class="statistic__tax">

    <div class="statistic__tax-revenues">
      <h5 v-if="getTaxRevenues.period.mounth && getTaxRevenues.period.year" class="title-h5 statistic__subtitle">
        {{ $t('statistic.tax_revenues_period_subtitle', {m: getTaxRevenues.period.mounth, y: getTaxRevenues.period.year}) }}
      </h5>
      <div class="statistic__info">
        <div class="statistic__info-col">
          <div class="statistic__info-item">
            <div class="statistic__info-item-title">{{ $t('statistic.received') }}</div>
            <div class="statistic__info-item-content">{{ taxRevenuesTotalSum }} {{ $t('main.finance.thousands') }}</div>
          </div>
        </div>
        <div class="statistic__info-col">
          <div class="statistic__info-item">
            <div class="statistic__info-item-title">{{ $t('statistic.total_taxpayers') }}</div>
            <div class="statistic__info-item-content">{{ taxRevenuesTotalTaxpayersPaid }}</div>
          </div>
        </div>
        <div class="statistic__info-col">
          <div class="statistic__info-item">
            <div class="statistic__info-item-title">{{ $t('statistic.taxpayers_legal') }}</div>
            <div class="statistic__info-item-content">{{ taxRevenuesTotalTaxpayersPaidIndividual }}</div>
          </div>
          <div class="statistic__info-item">
            <div class="statistic__info-item-title">{{ $t('statistic.taxpayers_individual') }}</div>
            <div class="statistic__info-item-content">{{ taxRevenuesTotalTaxpayersPaidLegal }}</div>
          </div>
        </div>
      </div>

      <div class="statistic__period">
        <StatisticsInfoBlock
          :title="$t('statistic.total_taxpayers_info')"
          :content="taxRevenuesTaxpayersTotal"
          :growth-percent="getTaxRevenuesTaxpayersGrowth"
        />
        <SelectField
          v-model="currentPeriod"
          :items="!!period ? period : []"
          :isDisabled="!!!period"
          titleKey="period"
          :title="$t('sections.updated_period')"
          placeholder=""
          valueToReturn="object"
          isObjectType
          class="statistic__filter-item"
        />
      </div>

      <h5 class="title-h5 mb-40 statistic__subtitle">{{ $t('statistic.tax_revenues_table_title') }}</h5>
      <div class="statistic__table-block">
        <StatisticsTable
          :table-head="taxRevenuesTableHead"
          :table="{items: taxRevenuesTable}"
          :table-footer="taxRevenuesTableFooter"
          class=" statistic__table statistic__table-item statistic__table-tax-revenues"
          @tableHandler="taxRevenuesTableHandler()"
        >
          <template #tax_revenues__sum="props">
            {{props.data.tax_revenues__sum}}

            <template v-if="props.data.total_sum_growth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[props.data.total_sum_growth > 0 ? 'grouwth' : 'decrease']"
              />
              <sup :class="{'statistic__table-growth-num': true, up: props.data.total_sum_growth > 0, down: props.data.total_sum_growth <= 0}">
                {{ props.data.total_sum_growth > 0 ? '+' : ''}}{{ props.data.total_sum_growth }}%
              </sup>
            </template>
          </template>
          <template #tax_revenues__total_taxpayers="props">
            {{props.data.tax_revenues__total_taxpayers}}

            <template v-if="props.data.total_taxpayers_growth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[props.data.total_taxpayers_growth > 0 ? 'grouwth' : 'decrease']"
              />
              <sup :class="{'statistic__table-growth-num': true, up: props.data.total_taxpayers_growth > 0, down: props.data.total_taxpayers_growth <= 0}">
                {{ props.data.total_taxpayers_growth > 0 ? '+' : ''}}{{ props.data.total_taxpayers_growth }}%
              </sup>
            </template>
          </template>
          <template #tax_revenues__percent="props">
            {{props.data.tax_revenues__percent}}

            <template v-if="props.data.percent_growth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[props.data.percent_growth > 0 ? 'grouwth' : 'decrease']"
              />
              <sup :class="{'statistic__table-growth-num': true, up: props.data.percent_growth > 0, down: props.data.percent_growth <= 0}">
                {{ props.data.percent_growth > 0 ? '+' : ''}}{{ props.data.percent_growth }}%
              </sup>
            </template>
          </template>

          <template #tableFooter_total_sum="props">
            {{props.data.total_sum}}

            <template v-if="getTaxRevenuesTableTotalSumGrowth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[getTaxRevenuesTableTotalSumGrowth > 0 ? 'grouwth' : 'decrease']"
              />
            </template>
          </template>
          <template #tableFooter_total_taxpayers="props">
            {{props.data.total_taxpayers}}

            <template v-if="getTaxRevenuesTaxpayersGrowth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[getTaxRevenuesTaxpayersGrowth > 0 ? 'grouwth' : 'decrease']"
              />
            </template>
          </template>
        </StatisticsTable>

        <div class="statistic__view-item">
          <div class="statistic__widget">

            <div
              v-for="(item, i) in legendItems" :key="i"
              class="statistic__widget-legend-item"
            >
              <span class="statistic__widget-legend-item-point" :style="{background: item.fillStyle}"></span>
              <span class="statistic__widget-legend-item-text">{{ item.text }}</span>
            </div>

            <current-widgets
              :widgets-data="widgetsData"
              :isWidget="isWidget"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="statistic__tourism-tax">
      <div class="statistic__title-wrapper">
        <h2 class="title-h2 statistic__title">
          {{ $t(`sections.statistic`) }}
        </h2>
      </div>
      <h5 v-if="getTourismTax.period.mounth && getTourismTax.period.year" class="title-h5 statistic__subtitle">
        {{ $t('statistic.tourism_tax_period_subtitle', {m: getTourismTax.period.mounth, y: getTourismTax.period.year}) }}
      </h5>

      <div class="statistic__info">
        <div class="statistic__info-col">
          <div class="statistic__info-item">
            <div class="statistic__info-item-title">{{ $t('statistic.received') }}</div>
            <div class="statistic__info-item-content">{{ tourismTaxTotalSum }} {{ $t('main.finance.thousands') }}</div>
          </div>
        </div>
      </div>

      <h5 class="title-h5 mb-40 statistic__subtitle">{{ $t('statistic.tourism_tax_table_title') }}</h5>
      <div class="statistic__table-block">
        <StatisticsTable
          :table-head="tourismTaxTableHead"
          :table="{items: tourismTaxTable}"
          :table-footer="tourismTaxTableFooter"
          class=" statistic__table statistic__table-item statistic__table-tourism-tax"
          @tableHandler="tourismTaxTableHandler()"
        >
          <template #tourism_tax__sum="props">
            {{ parseInt(props.data.tourism_tax__sum) != 0 ? props.data.tourism_tax__sum : '-' }}
          </template>
          <template #tourism_tax__dynamic="props">
            <template v-if="props.data.tourism_tax__total_sum_growth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[props.data.tourism_tax__total_sum_growth > 0 ? 'grouwth' : 'decrease']"
              />
            </template>
            <template v-else><span class="statistic-table__content-item--empty">-</span></template>
          </template>
          <template #tourism_tax__total_sum_growth="props">
            {{ parseFloat(props.data.tourism_tax__total_sum_growth) > 0 ? '+' : ''}}{{ props.data.tourism_tax__total_sum_growth }}
          </template>

          <template #tableFooter_total_growth>
            <template v-if="getTourismTaxTableTotalSumGrowth != 0">
              <svg-icon
                name="arrow-statistic"
                class="statistic__info-arrow"
                :class="[getTourismTaxTableTotalSumGrowth > 0 ? 'grouwth' : 'decrease']"
              />
            </template>
          </template>
        </StatisticsTable>

        <div class="statistic__tourism-tax-map-col">
          <MapRegions
            class="statistic__tourism-tax-map"
            :data="tourismTaxMapData"
          />

          <div class="statistic__tourism-tax-map-legend">
            <div class="statistic__tourism-tax-map-legend-row">
              <div class="statistic__tourism-tax-map-legend-label">{{ $t('statistic.growth_up') }}</div>
              <div class="statistic__tourism-tax-map-legend-label">{{ $t('statistic.growth_down') }}</div>
            </div>
            <div class="statistic__tourism-tax-map-legend-point"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { savedTourismReports } from "@/api/services/landing";
import SelectField from "@/elements/SelectField.vue";
import StatisticsInfoBlock from '@/elements/StatisticsInfoBlock.vue';
import StatisticsTable from "@/elements/StatisticsTable.vue";
import currentWidgets from "@/components/charts/currentWidgets.vue";
import MapRegions from '@/elements/MapRegions.vue';
import { mapGetters } from "vuex";
import { pickRgbRange, numberFormat } from '@/library/helpers.js'

export default {

  components: {
    SelectField,
    StatisticsInfoBlock,
    StatisticsTable,
    currentWidgets,
    MapRegions,
  },
  
  props: {
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      currentPeriod: null,
      period: null,
      widgetsData: null,
      isWidget: false,
      legendItems: [],
      taxRevenuesTableHead: [
        'tax_revenues__name',
        'tax_revenues__sum',
        'tax_revenues__total_taxpayers',
        'tax_revenues__percent',
      ],

      tourismTaxTableHead: [
        'tourism_tax__name',
        'tourism_tax__sum',
        'tourism_tax__dynamic',
        'tourism_tax__total_sum_growth',
      ],
    }
  },

  computed: {
    ...mapGetters([
      'getWidgetColors',
      'getTableSortData',
      'getTaxRevenues',
      'getTaxRevenuesTable',
      'getTaxRevenuesTableTotalSum',
      'getTaxRevenuesTaxpayersTotal',
      'getTaxRevenuesTaxpayersGrowth',
      'getTaxRevenuesTableTotalSumGrowth',

      'getTourismTax',
      'getTourismTaxTable',
      'getTourismTaxTableTotalSum',
      'getTourismTaxTableTotalSumGrowth',
    ]),

    taxRevenuesTotalSum() {
      return numberFormat(this.getTaxRevenues.total_sum / 1000, 2, 3, ' ', ',')
    },

    taxRevenuesTotalTaxpayersPaid() {
      return numberFormat(this.getTaxRevenues.total_taxpayers_paid, 0, 3, ' ', '.')
    },

    taxRevenuesTotalTaxpayersPaidIndividual() {
      return numberFormat(this.getTaxRevenues.total_taxpayers_paid_individual, 0, 3, ' ', '.')
    },

    taxRevenuesTotalTaxpayersPaidLegal() {
      return numberFormat(this.getTaxRevenues.total_taxpayers_paid_legal, 0, 3, ' ', '.')
    },

    taxRevenuesTaxpayersTotal() {
      return numberFormat(this.getTaxRevenuesTaxpayersTotal, 0, 3, ' ', '.')
    },

    taxRevenuesTable() {
      return this.getTaxRevenuesTable.map((row) => {
        row.tax_revenues__sum = numberFormat(row.sum / 1000, 1, 3, ' ', ',')
        row.tax_revenues__name = row.name
        row.tax_revenues__total_taxpayers = numberFormat(row.total_taxpayers, 0, 3, ' ', ',')
        row.tax_revenues__percent = numberFormat(row.percent, 1, 3, ' ', ',')

        return row
      })
    },

    taxRevenuesTableFooter() {
      return {
        total_sum: numberFormat(this.getTaxRevenuesTableTotalSum / 1000, 1, 3, ' ', ','),
        total_taxpayers: numberFormat(this.getTaxRevenuesTaxpayersTotal, 0, 3, ' ', '.'),
        total_percent: '100%',
      }
    },

    tourismTaxTotalSum() {
      return numberFormat(this.getTourismTax.total_sum / 1000, 2, 3, ' ', ',')
    },

    tourismTaxTable() {
      return this.getTourismTaxTable.map((row) => {
        row.tourism_tax__name = row.name
        row.tourism_tax__sum = numberFormat(row.sum / 1000, 1, 3, ' ', ',')
        row.tourism_tax__dynamic = ''
        row.tourism_tax__total_sum_growth = numberFormat(row.total_sum_growth, 1, 3, ' ', '.')

        return row
      })
    },

    tourismTaxTableFooter() {
      const growth = numberFormat(this.getTourismTaxTableTotalSumGrowth, 1, 3, ' ', ',')

      return {
        total_sum: numberFormat(this.getTourismTaxTableTotalSum / 1000, 1, 3, ' ', ','),
        total_growth: '',
        total_sum_growth: parseFloat(growth) > 0 ? `+${growth}%` : `${growth}%`,
      }
    },

    tourismTaxMapData() {
      const colors = {
        up: [
          {color: [235,235,235,1], position: 0},
          {color: [137,180,251,1], position: 8},
          {color: [109,159,250,1], position: 45},
          {color: [20,89,147,1], position: 100},
        ],
        down: [
          {color: [235,235,235,1], position: 0},
          {color: [255,225,185,1], position: 1},
          {color: [255,196,118,1], position: 15},
          {color: [255,190,104,1], position: 40},
        ],
      }

      return this.getTourismTaxTable.map(row => {

        const percentOfTotal = Math.abs(row.total_sum_growth) < 10 ? Math.abs(row.total_sum_growth) : Math.round(Math.abs(row.total_sum_growth) / 5) * 5
        const color = pickRgbRange(percentOfTotal, row.total_sum_growth > 0 ? colors.up : colors.down)

        const growth = row.total_sum_growth == 0 ? 0 : numberFormat(row.total_sum_growth, 1, 3, ' ', ',')

        return {
            katottg: row.state_katottg,
            fill: `rgb(${color.join(',')})`,
            textStyle: {transform: 'translateX(-10px)', fontSize: '1.3rem'},
            content: {
              text: row.total_sum_growth != 0 ? row.total_sum_growth > 0 ? `+${growth}%` : `${growth}%` : '',
            }
          }
      })
    },

  },

  async created() {
    await this.$store.dispatch('fetchTaxRevenues');
    await this.getSavedTourismReports();
    await this.fetchTaxRevenuesTable();
    await this.$store.dispatch('fetchTourismTax');
    await this.fetchTourismTaxTable();
  },

  watch: {

    currentPeriod: {
      handler(val) {
        if (!!this.period?.length && !!val) {
          this.taxRevenuesTableHandler();
          this.tourismTaxTableHandler();
        }
      },
      deep: true,
    },
    '$route': {
      async handler() {
        await this.$store.dispatch('fetchTaxRevenues');
        await this.getSavedTourismReports();
        await this.fetchTaxRevenuesTable();
        await this.$store.dispatch('fetchTourismTax');
        await this.fetchTourismTaxTable();
      }
    },

  },

  methods: {
     async getSavedTourismReports() {
      let res;
      try {
        res = await savedTourismReports(this.type);
        if (res.status === 200) {
          this.period = await res.data.data.reports;
          this.currentPeriod = await res.data.data.reports[0];
        }
      } catch (e) {
        console.log(">>> getSavedTourismReports error", e.response.data);
      }
    },

    async taxRevenuesTableHandler() {
      let sortData = {}

      for(let k in this.getTableSortData) {
        sortData[k] = typeof this.getTableSortData[k] === 'string' ? this.getTableSortData[k].replace('tax_revenues__', '') : this.getTableSortData[k]
      }

      await this.fetchTaxRevenuesTable(sortData)
    },

    async fetchTaxRevenuesTable(params = {}) {

      if(!this.currentPeriod?.year) return;

      await this.$store.dispatch('fetchTaxRevenuesTable', {
        quarter: this.currentPeriod?.quarter,
        year: this.currentPeriod?.year,
        ...params,
      });

      await this.setWidgetsData(this.getTaxRevenuesTable)
    },

    async setWidgetsData(data) {
      this.isWidget = !data.every((el) => el.total_taxpayers === 0);

      this.widgetsData = [
        {
          id: this.type,
          type: "doughnut",
          data: {
            labels: await data.map((el) => {
              const arr = el.name.split(' ')
              let label = []
              let str = ''

              arr.forEach((row, i) => {
                if(i !== 0 && i % 3 === 0) {
                  str += `${row} `
                  label.push(str.trim())
                  str = ''
                } else if(i !== 0 && i + 1 === arr.length) {
                  str += `${row} `
                  label.push(str.trim())
                } else {
                  str += `${row} `
                }
              })

              return label
            }),
            datasets: [
              {
                data: await data.map((el) => el.total_taxpayers),
                backgroundColor: this.getWidgetColors.background,
                hoverBackgroundColor: this.getWidgetColors.hover,
              },
            ],
          },

          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            animation: {
              onComplete: (instance) => {
                this.legendItems = instance?.chart?.legend?.legendItems.map(row => {
                  row.text = typeof row.text === 'object' ? row?.text?.join(' ') : row.text

                  return row
                })
              }
            },
          }
        },
      ];
    },

    async fetchTourismTaxTable(params = {}) {

      if(!this.currentPeriod?.year) return;
      
      await this.$store.dispatch('fetchTourismTaxTable', {
        quarter: this.currentPeriod?.quarter,
        year: this.currentPeriod?.year,
        ...params,
      });
    },

    async tourismTaxTableHandler() {
      let sortData = {}

      for(let k in this.getTableSortData) {
        sortData[k] = typeof this.getTableSortData[k] === 'string' ? this.getTableSortData[k].replace('tourism_tax__', '') : this.getTableSortData[k]
      }

      await this.fetchTourismTaxTable(sortData)
    },
  },

}
</script>

<style lang="sass" scoped>
.statistic

  &__subtitle
    color: #7F7F7F

  &__info
    display: flex
    width: 100%
    margin: 60px 0

    @include m
      margin: 40px 0

    @include s
      flex-wrap: wrap

    &-col

      @include s
        margin-bottom: 24px

        &:last-child
          margin-bottom: 0

      &:nth-child(1)
        width: 42%

        @include s
          width: 50%

        @include xs
          width: 100%
          border-bottom: 1px solid #989898
          padding-bottom: 12px

      &:nth-child(2)
        width: 30%

        @include m
          width: 40%

        @include s
          width: 50%

        @include xs
          width: 100%
          border-bottom: 1px solid #989898
          padding-bottom: 12px

      &:nth-child(3)
        width: 25%

        @include m
          width: 15%

        @include s
          width: 100%
          display: flex

        .statistic__info-item
          &-title
            margin-bottom: 20px

    &-item
      width: 100%

      &:not(:first-child)
        margin-top: 36px

        @include s
          margin-top: 0
      
      &-title
        font-size: 18px
        margin-bottom: 32px
        color: #989898

        @include m
          margin-bottom: 16px

      &-content
        font-size: 48px
        line-height: 32px

        @include m
          font-size: 32px

  &__period
    display: flex
    align-items: flex-end
    margin-bottom: 7rem
    @include xs
      margin-bottom: 30px
      flex-direction: column

  &__filter-item
    width: 30%
    max-width: 350px
    @include s
      width: 50%
      max-width: 50%
    @include xs
      width: 100%
      max-width: 100%
    &:first-child
      margin-right: 60px
      @include xs
        margin-right: 0
        margin-bottom: 30px

  &__table

    @include m
      margin-bottom: 0

    &-growth
      &-num
        &.up
          color: #47B444
        &.down
          color: #FF5552

    .statistic__info-arrow
      margin-left: .5rem

    ::v-deep
      .statistic-table__content-item:not(.statistic-table__content-item:first-child, .statistic-table__content-item:last-child, .statistic-table__growth-item)
        display: inline-flex
        align-items: baseline
        white-space: nowrap

      @include xs
        .statistic-table__content-item
          flex-wrap: nowrap
          display: flex

        .statistic-table__header-title
          width: 45%
          white-space: normal
          min-width: 45%

        .statistic-table__content
          padding: 5px 0

        .statistic-table__content-item:not(.statistic-table__content-item:first-child, .statistic-table__content-item:last-child, .statistic-table__growth-item)
          padding: 8px 5px

        .statistic-table__content-item:last-child
          padding-right: 5px !important

    &-tax-revenues
      ::v-deep
        .statistic-table__greed
          padding: 2rem 0
          height: auto !important
          max-height: initial
          min-height: 85px
          grid-template-columns: minmax(25rem, 3.5fr) minmax(15rem, 2fr) minmax(6.9rem, 2fr) minmax(10rem, 2fr)

          @include xs
            padding: 5px 0
            min-height: auto

        .statistic__info-arrow
          min-width: 12px
          min-height: 6px

        .statistic-table__footer
          padding: 0
          min-height: initial

        .statistic-table__content-item:last-child
          padding-right: 2rem

    &-tourism-tax
      ::v-deep
        .statistic-table__greed
          grid-template-columns: minmax(25rem, 3.5fr) minmax(15rem, 2.5fr) minmax(15rem, 2.5fr) minmax(6rem, 1fr)
        
        .statistic-table__content-item .statistic-table__content-item--empty
          display: inline-flex
          padding-left: 7px

        .statistic-table__footer-item svg
          margin-left: 11px !important

  &__table-block
    gap: 74px

    @include m
      gap: 42px

    .statistic__widget
      width: 100%

      @include m
        display: flex
        flex-direction: column
        align-items: center

      ::v-deep
        .chart-item
          margin-top: 40px
          max-width: initial
        .chart__wrapper
          max-width: 296px
          max-height: 296px

      &-legend-item
        display: flex
        align-items: center
        margin-bottom: 14px
        color: rgba(0, 0, 0, 0.67)
        font-size: 13px

        @include m
          text-align: left
          justify-content: flex-start
          width: 500px
          max-width: 100%
          margin-left: 10%

        @include xs
          margin-left: 0

        &:last-child
          margin-bottom: 0

        &-point 
          display: inline-flex
          min-width: 40px
          height: 12px
          margin-right: 24px

  &__tourism-tax
    margin-top: 72px

    &-map

      @include m
        margin-top: 0

      &-col
        display: flex
        flex-direction: column
        width: 35%
        min-width: 400px

        @include m
          width: 100%
          min-width: initial

      &-legend
        margin-top: 28px

        &-row
          display: flex
          width: 100%
          justify-content: space-between

        &-point
          margin-top: 8px
          width: 100%
          height: 16px
          background: linear-gradient(90deg, #FFBE68 0%, #FECD8C 38.82%, #77A5F9 62.34%, #2075BC 100%)

</style>