var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistic-table"},[[_c('div',{staticClass:"statistic-table__wrapper"},[_c('div',{staticClass:"statistic-table__greed statistic-table__header"},_vm._l((_vm.tableHead),function(item){return _c('div',{key:item,staticClass:"statistic-table__header-item",class:{
            'statistic-table__header-item_growth':
              item === 'growth_percentage',
            'statistic-table__sort_dwn': _vm.sort === 'dwn' && item === _vm.sortBy,
          },on:{"click":function($event){return _vm.sortHandler(item)}}},[(item === 'name')?[_vm._v(" "+_vm._s(_vm.$t('table.country_name'))+" ")]:(item === 'growth_percentage')?[_vm._v(" % ")]:(_vm.isStarCategoryHead(item))?[_vm._v(" "+_vm._s(_vm.$t(("table." + item)))+" "),_c('svg',{attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_83_1348)"}},[_c('path',{attrs:{"d":"M11.25 4.875H7.21875L6 1.125L4.78125 4.875H0.75L4.03125 7.125L2.76562 10.875L6 8.53125L9.23438 10.875L7.96875 7.125L11.25 4.875Z","fill":"#EDC90D","stroke":"#EDC90D","stroke-opacity":"0.87","stroke-width":"0.5","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_83_1348"}},[_c('rect',{attrs:{"width":"12","height":"12","fill":"white"}})])])])]:[_vm._v(" "+_vm._s(_vm.$t(("table." + item)))+" ")],_c('svg-icon',{staticClass:"statistic-table__arrow-ico statistic-table__header-ico",attrs:{"name":"arrow-statistic"}})],2)}),0),(_vm.isTable)?_vm._l((_vm.getTableContent),function(item){return _c('div',{key:item.name,staticClass:"statistic-table__content statistic-table__greed"},_vm._l((_vm.tableHead),function(headerItem){return _c('div',{key:headerItem,staticClass:"statistic-table__content-item",class:{
              'statistic-table__growth-item':
                headerItem === 'growth_percentage',
            }},[_c('div',{staticClass:"statistic-table__header-title"},[(headerItem === 'name')?[_vm._v(" "+_vm._s(_vm.$t('table.country_name'))+": ")]:(headerItem === 'growth_percentage')?[_vm._v(" %: ")]:(_vm.isStarCategoryHead(headerItem))?[_c('star-rating',{attrs:{"rating":parseInt(headerItem.replace('star_category_', '')),"active-on-click":false,"max-rating":parseInt(headerItem.replace('star_category_', '')),"star-size":14,"fixed-points":1,"show-rating":false,"clearable":false,"padding":4,"border-width":2,"inline":true,"read-only":true,"border-color":"#6B6B6B","active-border-color":"#FFB33B","active-color":"#FFB33B","inactive-color":"#fff"}})]:[_vm._v(" "+_vm._s(_vm.$t(("table." + headerItem)))+": ")]],2),(headerItem === 'growth_percentage')?[_vm._v(" "+_vm._s(!!+item[headerItem] ? ((Math.floor(+item[headerItem] * 100) / 100) + "%") : '-')+" "),_c('svg-icon',{staticClass:"statistic-table__arrow-ico",class:[
                  !!+item[headerItem] && +item[headerItem] > 0
                    ? 'grouwth'
                    : 'decrease' ],attrs:{"name":"arrow-statistic"}})]:(headerItem === 'number_of_tourists_in_percent')?[_vm._v(" "+_vm._s(!!+item[headerItem] ? ((Math.floor(+item[headerItem] * 100) / 100) + "%") : '-')+" ")]:[_vm._t(headerItem,function(){return [_vm._v(_vm._s(!!item[headerItem] ? item[headerItem] : '-'))]},{"data":item})]],2)}),0)}):[_c('div',{staticClass:"statistic-table__no-data"},[_c('NoData')],1)],(_vm.tableFooter)?_c('div',{staticClass:"statistic-table__footer statistic-table__greed"},[_c('div',{staticClass:"statistic-table__footer-item"},[_vm._v(" "+_vm._s(_vm.$t('table.total_result'))+" ")]),_vm._l((_vm.tableFooter),function(item,i){return _c('div',{key:i,staticClass:"statistic-table__footer-item"},[_vm._t(("tableFooter_" + i),function(){return [_vm._v(_vm._s(item))]},{"data":_vm.tableFooter})],2)})],2):_vm._e(),(_vm.isTable)?_c('SimplePagination',{attrs:{"paginationData":_vm.getPaginationData},on:{"detectedPageValue":_vm.detectedPageValue}}):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }