<template>
  <div id="statistic" class="statistic main__wrapper">
    <!-- <div class="block__back">
      <svg-icon class="icon-arrow-back" name="arrow-back" />
      <h2 class="title-h5 router-link-black">{{ $t(`modals.back`) }}</h2>
    </div> -->

    <div class="statistic__title-wrapper">
      <h2 class="title-h2 statistic__title">
        {{ $t(`statistic.${type}_title`) }}
      </h2>
    </div>
    <div v-if="type === 'outbound'" class="statistic__title-alert">{{ $t(`statistic.${type}_title_alert`) }}</div>
    <h5 v-if="subtitle" class="title-h5 statistic__subtitle">
      {{ subtitle }}
    </h5>

    <div class="statistic__main">
      <component :is="viewComponentName" :type="type" />
    </div>

    <div class="statistic__related" v-if="statisticPages.length">
      <router-link
        v-for="(item) in statisticPages"
        :key="item.id"
        :to="item.id"
        class="statistic__related-item"
      >
        <span class="statistic__related-item-text">{{ $t(`statistic.${item.type}`) }}</span>
        <svg-icon
          class="statistic__related-item-icon"
          name="arrow"
        />
      </router-link>
    </div>

    <div v-if="getResearchFiles.length" class="statistic__research">
      <div class="statistic__research-title">{{ $t('statistic.title_research_download') }}</div>
      <div class="statistic__research-list">
        <div class="statistic__research-item" v-for="(item) in getResearchFiles" :key="item.uuid">
          <div @click="schowDocument(item.attachment.original_url)" class="statistic__research-item-link">
            <span class="statistic__research-item-left">
              <svg-icon
                class="statistic__research-item-icon"
                name="pdf"
              />
            </span>
            <span class="statistic__research-item-right">
              <span class="statistic__research-item-title">{{ item.title }}</span>
              <svg-icon
                class="statistic__research-item-icon"
                name="arrow-download"
              />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type === 'inbound'" class="inbound__content">
      <div class="inbound__text">
        <p>{{ $t('statistic.inbound_research_text') }}</p>
      </div>
      <div class="inbound__actions">
        <Button @click.native="schowIframe(getInboundResearchIframeUrl)">{{ $t('buttons.look_here') }}</Button>
      </div>
    </div>

  </div>
</template>

<script>
import StatistictTypeTourism from './StatistictTypeTourism.vue';
import StatisticDomesticTourism from './StatisticDomesticTourism.vue';
import StatisticTaxRevenues from './StatisticTaxRevenues.vue';
import SelectField from '@/elements/SelectField.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    StatistictTypeTourism,
    StatisticDomesticTourism,
    StatisticTaxRevenues,
    SelectField,
  },
  data() {
    return {};
  },

  async created() {
    await this.$store.dispatch('fetchResearchFiles', {
      'filter[type]': this.type,
    });
  },

  watch: {
    '$route': {
      async handler(val) {
        await this.$store.dispatch('fetchResearchFiles', {
          'filter[type]': this.type,
        });
      }
    },
  },

  computed: {
    ...mapGetters(['getStatisticPages', 'getResearchFiles', 'getInboundResearchIframeUrl']),

    type() {
      return this.$store.getters.getStatisticType(this.$route.params.id);
    },

    subtitle() {
      return this.$t(`statistic.${this.type}_subtitle`);
    },

    viewComponentName() {
      let components = {
        inbound: 'StatistictTypeTourism',
        outbound: 'StatistictTypeTourism',
        domestic_tourism: 'StatisticDomesticTourism',
        tax_revenues: 'StatisticTaxRevenues',
      }

      return components[this.type];
    },

    statisticPages() {
      return this.getStatisticPages.filter(el => el.type !== this.type)
    },
  },

  methods: {
    schowDocument(url) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'pdf-view',
        size: 'xl',
        props: { url, isPdfViewer: true },
      });
    },

    schowIframe(url) {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'iframe-view',
        size: 'xl',
        props: { url },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.statistic__title-wrapper
  display: flex
  justify-content: space-between
  margin-bottom: 2rem
  align-items: center
.statistic__subtitle
  color: $gray_notification
  margin-bottom: 6rem
  @include xs
    margin-bottom: 3rem
.statistic__details-link
  color: black
  white-space: nowrap

.statistic__type-el
  &:first-child
    // margin-bottom: 7rem
    // border-bottom: solid 2px $black
    padding-bottom: 40px
    @include xs
      margin-bottom: 30px

.statistic
  > *:last-child
    margin-bottom: 0

  &.main__wrapper
    padding-bottom: 7rem

  &__title
    &-alert
      margin-bottom: 4rem

  &__related
    display: flex
    justify-content: space-between
    margin: 72px 0

    @include s
      flex-wrap: wrap

    &-item
      display: inline-flex
      justify-content: space-between
      color: #000
      font-size: 40px
      line-height: 48px
      border-top: 2px solid #000
      padding: 16px 0
      width: 26%
      font-weight: 300
      transition: all .3s ease 0s

      @include l
        font-size: 32px
        line-height: 40px

      @include s
        font-size: 20px
        line-height: 24px

      @include xs
        width: 100%
        margin-bottom: 48px
        padding: 12px 0 0 0

        &:first-child
          border-top: none

        &:last-child
          margin-bottom: 0

      &:hover
        text-decoration: underline
        text-underline-offset: 9px
        text-decoration-thickness: 2px

        .statistic__related-item-icon
          opacity: 1

      &-text
        width: min-content

        @include xs
          width: auto

      &-icon
        height: 24px
        width: 24px
        margin-top: 1rem
        opacity: 0
        transition: all .3s ease 0s

        @include s
          opacity: 1
          width: 20px
          height: 20px
          margin-top: 0.5rem

  &__research
    margin: 72px 0

    &-title
      font-size: 24px
      line-height: 36px
      margin-bottom: 16px

      @include xs
        font-size: 20px

    &-list
      display: flex
      flex-wrap: wrap
      width: 100%
      gap: 2rem 5%

      @include s
        gap: 2rem 4%

      @include xs
        gap: 2rem 0

    &-item
      width: 30%

      @include s
        width: 48%

      @include xs
        width: 100%

      &-link
        cursor: pointer
        display: flex
        height: 64px
        border-radius: 12px
        border: 1px solid #989898
        overflow: hidden
        transition: all .3s ease 0s
        color: #000

        &:hover
          border-color: #000

      &-left
        display: flex
        width: 64px
        background: #000
        align-items: center
        justify-content: center

        .svg-icon
          fill: #fff
          width: 40px
          height: 40px

      &-right
        display: flex
        justify-content: space-between
        align-items: center
        width: calc(100% - 64px)
        padding: 0 16px

        .svg-icon
          margin-left: 1rem
          min-width: 32px
      
      &-title
        white-space: nowrap
        display: block
        overflow: hidden
        text-overflow: ellipsis

.inbound
  &__content
    padding-top: 3rem
    border-top: 2px solid #000
    margin: 72px 0

  &__text
    font-size: 28px
    line-height: 40px
    text-align: center
    max-width: 1040px
    margin: auto

    @include m
      font-size: 20px
      line-height: 32px

  &__actions
    margin-top: 4rem
    display: flex
    justify-content: center
</style>
