<template>
  <div class="chart chart__wrapper">
    <div v-for="chart in widgetsData" :key="chart.id" class="chart__items">
      <widget-chart
        :key="isReload"
        :chart-data="chart.data"
        :chart-type="chart.type"
        :chart-options="chart.options"
        :chart-id="chart.id"
        :isWidget="isWidget"
        @reloaded="isReload = false"
      />
    </div>
    <div class="chart__empty" v-if="!isWidget">
      <div class="chart__empty-txt">
        {{ $t('table.no_data') }}
      </div>
    </div>
  </div>
</template>

<script>
import widgetChart from '@/components/charts/widgetChart.vue';
export default {
  components: {
    widgetChart,
  },
  props: {
    widgetsData: {
      type: Array,
      default: () => [],
    },
    isWidget: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isReload: false,
    };
  },

  watch: {
    widgetsData: {
      handler() {
        this.isReload = true;
      },
      deep: true,
    },
  },

  computed: {},

  methods: {},
};
</script>

<style lang="sass" scoped>
.chart__wrapper
  min-height: 290px
  max-height: 390px
  min-width: 290px
  max-width: 390px
  position: relative
  // border: solid 1px red
.chart__empty
  position: absolute
  width: 242px
  height: 242px
  bottom: 0px
  left: 50%
  transform: translateX(-50%)
  border-radius: 50%
  background: $gray_border
  // border: solid 1px red
  &:after
    content: ''
    width: 50%
    height: 50%
    border-radius: 50%
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    display: block
    position: absolute
    background: $white

.chart__empty-txt
  text-align: center
  color: #C4C4C4
  font-size: 20px
  position: relative
  top: 50%
  transform: translateY(-50%)
  z-index: 10
</style>
