<template>
  <div
    class="tag_input_wrapper"
    :class="{ active: isOpenOptions }"
    v-click-outside="onClickOutside"
  >
    <div class="select-title">
      {{ title }}
    </div>
    <svg-icon
      name="arrow-up-s"
      class="drop-down-icon"
      :class="isOpenOptions ? 'dropdown' : ''"
    />
    <div @click="toggleList" class="tag_input" :class="{ squares: selectedTags.length }">
      <div
        v-for="(tag, index) in selectedTags"
        :key="index"
        :style="getTagStyles(tag)"
        class="tag_input__tag"
      >
        <div class="tag_input__name">
          <p>{{ visibleSelectedTags(tag.name) }}</p>
          <!-- <div class="tag_cross" @click="removeClickedTag(index)">
            <svg-icon name="cross" />
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-if="selectedTags.length"
      :class="{ active: selectedTags.length }"
      class="clear-button"
      @click="clearQuery"
    >
      <svg-icon name="cross" class="cross-sm-icon" />
    </div>
    <div class="selected-item__input-wrapper" :style="getInputCursorWidth">
      <!-- @keyup.enter="addTag"
        @keyup.space="addTag" -->
      <input
        type="text"
        class="selected-item__input"
        :disabled="isDisabled"
        @input="onInput"
        @keydown.delete="removeLastTag"
        :id="inputId"
        @click="toggleList"
      />
    </div>
    <div v-show="isOpenOptions" class="dropdown-popover">
      <span v-if="isNotContentForOptions">
        {{ $t("main.no_search_result") }}
      </span>
      <div class="options">
        <ul>
          <li v-for="item in filteredContent" :key="item.id" class="tag_option">
            <Checkbox v-model="checkedItems" :value="item" />
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="horizontal-scroll-wrapper squares">
    <div>item 1</div>
    <div>item 2</div>
    <div>item 3</div>
    <div>item 4</div>
    <div>item 5</div>
    <div>item 6</div>
    <div>item 7</div>
    <div>item 8</div>
  </div> -->
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    inputId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tags: ["#Взуття", "#Аптечки"],
      isOpenOptions: false,
      filteredContent: [],
    };
  },
  computed: {
    getInputCursorWidth() {
      let windowWidth = window.innerWidth;
      let sum = 0;
      let width = windowWidth > 1150 ? 340 : 480;
      let maxSum = windowWidth > 1150 ? 21 : 36;
      let sumOfTagLenght = [];

      sumOfTagLenght = this.selectedTags.map((tag) => tag.name.length);
      sumOfTagLenght.forEach((el) => (sum += el));
      if (windowWidth <= 767) width = 760;

      if (sum >= maxSum) {
        width = windowWidth > 1150 ? 80 : 100;
      } else {
        width = width - sum * 14;
      }
      return { "max-width": `${width}px` };
    },
    isNotContentForOptions() {
      return this.filteredContent && this.filteredContent.length === 0;
    },
    checkedItems: {
      get() {
        return this.selectedTags;
      },
      set(val) {
        this.selectedTags = [...val];
        this.$emit("select-value", this.selectedTags);
      },
    },
    selectedTags: {
      get() {
        this.tags = this.value;
        return this.value;
      },
      set(value) {
        this.$emit("select-tags", value);
      },
    },
  },
  async mounted() {
    this.filteredContent = this.items;
  },
  methods: {
    visibleSelectedTags(tag) {
      return tag + ",";
    },
    getTagStyles(tag) {
      return {
        "min-height": `${tag.name.length * 10 + 10}px`,
      };
    },
    getTagCrossStyles(tag) {
      return {
        right: `30px`,
      };
    },
    onClickOutside() {
      this.isOpenOptions = false;
    },
    clearQuery() {
      this.selectedTags = [];
      this.filteredContent = this.items;
      this.isOpenOptions = false;
    },
    toggleList(event) {
      document.getElementById(this.inputId).focus();
      this.filteredContent = this.items;
      this.isOpenOptions = true;
    },
    onInput(event) {
      if (event.target.value) {
        this.filteredContent = this.items.filter((el) =>
          el.name.toLowerCase().includes(event.target.value.toLowerCase())
        );
      } else {
        this.filteredContent = this.items;
      }
    },
    addTag(event) {
      // if (this.tags.length >= 5) {
      //   event.target.value = '';
      //   return;
      // }
      event.preventDefault();
      let val = event.target.value.replace(/\s/g, "");
      // if (val.length >= 24) {
      //   val = val.slice(0, 24);
      // }
      if (val.length > 1) {
        this.tags.push(val);
        event.target.value = "";
      }
      this.$emit("select-tags", this.tags);
    },

    removeTag(index) {
      this.tags.splice(index, 1);
      this.$emit("select-tags", this.tags);
    },

    removeClickedTag(idx) {
      this.tags = this.tags.filter((el, i) => i !== idx);
      this.$emit("select-tags", this.tags);
    },

    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
        this.$emit("select-tags", this.tags);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.select-title
  color: $draft
  font-size: 16px
.tag_input_wrapper
  position: relative
  border-bottom: 2px solid $gray
.tag_input_wrapper.active
  border-bottom: 2px solid $black

.tag_input
  position: absolute
  // border-left: 2px solid $gray
  border: none
  top: 40px
  left: -18px
  min-width: 42px
  margin: 0
  max-height: 250px
  display: block
  overflow-y: auto
  overflow-x: hidden
  transform: rotate(-90deg) translateY(-180px)
  transform-origin: right top
  @media only screen and (max-width: 1150px)
    max-height: 400px

.tag_input::-webkit-scrollbar
  // height: 5px
  display: none
.tag_input::-webkit-scrollbar-track
.tag_input::-webkit-scrollbar-thumb

.tag_input__name
  position: relative
.tag_input__tag
  font-size: 1.4rem
  line-height: 1.8rem
  padding: 0
  // position: relative
  min-height: 50px
  cursor: pointer
  display: flex
  align-items: flex-start
  white-space: nowrap
  transform: rotate(90deg)
  transform-origin: right top

.squares
  padding: 200px 0 0 0

.tag_cross
  position: absolute
  right: -12px
  top: 0%
  // transform: translateY(-50%)

.tag_cross svg
  max-width: 7px
  max-height: 7px

.selected-item__input-wrapper
  align-self: end
  height: 40px
  width: 100%
  position: absolute
  right: 0
  top: 30px
  overflow: hidden
  z-index: 5

.selected-item__input
  font-size: 14px
  width: 100%
  height: 100%
  // border: 1px solid #000
  padding-left: 12px
  // text-overflow: ellipsis
  // vertical-align: middle
  &::placeholder
    color: $gray

.dropdown-popover
  border-radius: 1.2rem
  z-index: 99
  position: absolute
  border: 2px solid lightgrey
  top: 73px
  left: 0
  right: 0
  background-color: $white
  max-width: 100%
  padding: 10px
  input
    width: 90%
    height: 30px
    border: 2px solid lightgrey
    padding-left: 8px
  .options
    width: 100%
    &::after
      width: calc(100% - 2.4rem)
      position: absolute
      bottom: 0
      left: 1.2rem
      height: 10%
      content: ""
      background: linear-gradient(to top, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
    ul
      list-style: none
      text-align: left
      max-height: 180px
      overflow-y: scroll
      overflow-x: hidden
      font-size: 14px
      li
        display: flex
        width: 100%
        border-bottom: 1px solid lightgrey
        padding: 10px
        cursor: pointer
        &:hover
          background-color: $gray_border

.cross-sm-icon
  width: 9px
  opacity: 0.3
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  // filter: invert(77%) sepia(2%) saturate(0%) hue-rotate(318deg) brightness(90%) contrast(92%)
  transition: opacity 0.5s

.clear-button
  position: absolute
  right: 30px
  bottom: 12px
  width: 18px
  height: 18px
  border: solid 1px $gray
  background-color: $gray_light
  border-radius: 50%
  opacity: 0
  cursor: pointer
  transition: opacity 0.5s
  z-index: 700
.tag_input_wrapper:hover .clear-button.active
  border-color: $gray_active
  opacity: 1
  transition: opacity 0.3s

.error-message
  position: absolute
  padding-top: 5px
  font-size: 12px
  color: $error_color

.drop-down-icon
  fill: $gray
  transform: rotate(0deg)
  transition: all 0.4s ease
  position: absolute
  right: 0
  bottom: 10px
  height: 10px
  width: 13px
.drop-down-icon.dropdown
  transform: rotate(180deg)
  transition: all 0.4s ease
</style>
