<template>
  <div class="simple-pagination simple-pagination__wrapper">
    <div class="simple-pagination__pages">
      {{ paginationData.from }}-{{
        paginationData.to >= paginationData.total
          ? paginationData.total
          : paginationData.to
      }}
      /{{ paginationData.total }}
    </div>
    <div class="simple-pagination__control">
      <button
        class="simple-pagination__btn"
        :class="{ 'simple-pagination__btn_disabled': isStart }"
        @click="goToHandler(-1)"
        :disabled="isStart"
      >
        <svg-icon name="arrow-prev" class="simple-pagination__ico" />
      </button>
      <button
        class="simple-pagination__btn"
        :class="{ 'simple-pagination__btn_disabled': isEnd }"
        @click="goToHandler(1)"
        :disabled="isEnd"
      >
        <svg-icon name="arrow-next" class="simple-pagination__ico" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      currentPage: 0,
    };
  },

  created() {},

  computed: {
    ...mapGetters([]),

    isStart() {
      return this.paginationData?.current_page <= 1;
    },

    isEnd() {
      return this.paginationData?.to >= this.paginationData.last_page;
    },
  },

  methods: {
    goToHandler(val) {
      this.currentPage += val;
      this.$emit('detectedPageValue', this.currentPage);
    },
  },
};
</script>

<style lang="sass" scoped>
.simple-pagination__wrapper
  display: flex
  justify-content: flex-end
.simple-pagination__pages
  font-size: 16px
  color: $gray_notification
  margin-right: 10px
.simple-pagination__btn
  display: inline-flex
  padding: 5px 10px
  opacity: 0.7
  transition: opacity 0.15s
  &:hover
    &:not(.simple-pagination__btn_disabled)
      opacity: 1
      transition: opacity 0.15s
.simple-pagination__btn_disabled
  opacity: 0.4
.simple-pagination__ico
  width: 12px
  height: 12px
</style>
