<template>
  <div class="statistic__filter-item statistic__info-block">
    <h4 v-if="title" class="title-h6 statistic__info-title">
      {{ title }}
    </h4>
    <div v-if="content" class="statistic__info-tourists-block">
      <h2 class="title-h3 statistic__info-tourists-number">
        {{ content }}
      </h2>
      <div class="statistic__info-tourists-percent" v-if="growthPercent">
        {{ growthPercent != 0 ? growthPercent : '-' }}
        <svg-icon
          name="arrow-statistic"
          class="statistic__info-arrow"
          :class="[growthPercent > 0 ? 'grouwth' : 'decrease']"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },

    content: {
      type: String,
      default: '',
    },

    growthPercent: {
      type: Number,
      default: null,
    },
  }
}
</script>