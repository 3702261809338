<template>
  <div class="statistic-type statistic-type__wrapper">
    <h5 class="title-h5 mb-40">{{ $t(`sections.${type}_tourists`) }}</h5>
    <!-- {{ getContinents }} -->
    <div class="statistic-type__filter-block">
      <SelectTagsField
        v-model="currentContinents"
        :items="!!getContinents ? getContinents : []"
        :isDisabled="!!!getContinents"
        :inputId="`${type}_continents_tag_input`"
        :title="$t('sections.continent')"
        @select-tags="selectContinents"
        class="statistic-type__filter-select"
      />

      <SelectTagsField
        v-model="currentCountries"
        :items="!!countriesList ? countriesList : []"
        :isDisabled="!!!countriesList"
        :title="$t('sections.country')"
        :inputId="`${type}_countries_tag_input`"
        @select-tags="selectCountries"
        class="statistic-type__filter-select"
      />
    </div>
    <div class="statistic-type__period">
      <div class="statistic__filter-item statistic__info-block">
        <h4 class="title-h6 statistic__info-title">
          {{ $t("table.number_of_tourists") }}
        </h4>
        <div class="statistic__info-tourists-block">
          <h2 class="title-h3 statistic__info-tourists-number">
            {{ currentTouristsNumber }}
          </h2>
          <div class="statistic__info-tourists-percent">
            {{ currentGrowthPercentage }}
            <svg-icon
              name="arrow-statistic"
              class="statistic__info-arrow"
              :class="[isGrowth ? 'grouwth' : 'decrease']"
            />
          </div>
        </div>
      </div>
      <SelectField
        v-model="currentPeriod"
        :items="!!period ? period : []"
        :isDisabled="!!!period"
        titleKey="period"
        :title="$t('sections.updated_period')"
        placeholder=""
        valueToReturn="object"
        isObjectType
        class="statistic__filter-item"
      />
    </div>
    <div class="statistic__table-block">
      <StatisticsTable
        :table-head="tableHead"
        :table="table"
        :table-footer="tableFooter"
        class="statistic__table-item"
        @tableHandler="getStatisticsTable()"
      >
        <template #tableFooter_percent="props">
          {{props.data.percent}}
          <svg-icon
            name="arrow-statistic"
            class="statistic-table__arrow-ico"
            :class="[tableFooterisGrowth ? 'grouwth' : 'decrease']"
          />
        </template>
      </StatisticsTable>
      <div class="statistic__view-item">
        <div class="statistic__map">
          <div class="statistic__map-info" v-if="!!mapData">
            <div class="statistic__map-info-title">
              {{ $t("table.number_of_tourists") }}:
            </div>
            <div class="statistic__map-info-item">
              {{ $t("main.from") }}
              <span
                class="statistic__map-info-indicator statistic__map-info-indicator_from"
              />
              {{ getMinTouristsNumber }}
            </div>
            <div class="statistic__map-info-item">
              {{ $t("main.to") }}
              <span
                class="statistic__map-info-indicator statistic__map-info-indicator_to"
              />
              {{ getMaxTouristsNumber }}
            </div>
          </div>
          <div class="statistic__map-container">
            <Map :country="!!mapData ? mapData : []" />
          </div>
        </div>
        <div class="statistic__widget">
          <current-widgets :widgets-data="widgetsData" :isWidget="isWidget" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { savedTourismReports, statisticsTableTourism } from "@/api/services/landing";
import { getCountries } from "@/api/services/general.js";

import currentWidgets from "@/components/charts/currentWidgets.vue";
import StatisticsTable from "@/elements/StatisticsTable.vue";
import SelectField from "@/elements/SelectField.vue";
import SelectTagsField from "@/elements/SelectTagsField.vue";
import Map from "@/elements/Map.vue";
export default {
  components: {
    currentWidgets,
    StatisticsTable,
    SelectField,
    Map,
    SelectTagsField,
  },

  props: {
    type: {
      type: String,
      default: "",
    },
    continent: {
      type: Array,
      default: () => [],
    },
    country: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      countriesList: null,
      currentContinents: [],
      currentCountries: [],
      currentPeriod: null,
      period: null,
      table: null,
      curPage: 1,

      widgetsData: null,
      isWidget: false,
      mapData: null,
      tableHead: [
        'name',
        'number_of_tourists',
        'growth_percentage',
        'number_of_tourists_in_percent',
      ],
    };
  },

  async created() {
    await this.getSavedTourismReports();
    await this.getCountriesList();
    await this.$store.dispatch('getContinents');
  },

  watch: {
    currentContinents: {
      handler(val) {
        let data = val.map((el) => el.id);
        !!this.period?.length && this.getStatisticsTable();
        this.getCountriesList(data);
        this.currentCountries.splice(0);
      },
      deep: true,
    },
    currentCountries: {
      handler() {
        !!this.period?.length && this.getStatisticsTable();
      },
      deep: true,
    },
    currentPeriod: {
      handler(val) {
        if (!!this.period?.length && !!val) {
          this.getStatisticsTable();
        }
      },
      deep: true,
    },
    '$route': {
      async handler(val) {
        await this.getSavedTourismReports();
        await this.getCountriesList();
        await this.$store.dispatch('getContinents');
      }
    },
  },

  computed: {
    ...mapGetters(["getContinents", "getTableSortData", "getWidgetColors"]),

    currentTouristsNumber() {
      return !!this.table?.total_number_of_tourists
        ? this.table?.total_number_of_tourists
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        : 0;
    },

    currentGrowthPercentage() {
      return !!this.table?.total_growth_percentage
        ? `${Math.floor(+this.table?.total_growth_percentage * 100) / 100}%`
        : "-";
    },

    getCurrentTypeTourism() {
      return `${this.type}_tourism`;
    },

    isGrowth() {
      return (
        !!this.table?.total_growth_percentage && this.table?.total_growth_percentage > 0
      );
    },

    getMinTouristsNumber() {
      let arr = this.table?.items?.map((el) => el.number_of_tourists);
      if (!!arr) {
        return Math.min.apply(null, arr);
      }
    },

    getMaxTouristsNumber() {
      let arr = this.table?.items?.map((el) => el.number_of_tourists);
      if (!!arr) {
        return Math.max.apply(null, arr);
      }
    },

    tableFooter() {
      return {
        tourists: this.table?.total_number_of_tourists,
        percent: !!this.table
          ? `${Math.floor(+this.table?.total_growth_percentage * 100) / 100}%`
          : '0%',
        percentTotal: !!this.table?.items?.length ? '100%' : '0%',
      }
    },

    tableFooterisGrowth() {
      return !!this.table?.total_growth_percentage && this.table?.total_growth_percentage > 0
    },
  },

  methods: {
    selectContinents(val) {
      this.currentContinents = val;
    },
    selectCountries(val) {
      this.currentCountries = val;
    },
    async getSavedTourismReports() {
      let res;
      try {
        res = await savedTourismReports(this.getCurrentTypeTourism);
        if (res.status === 200) {
          this.period = await res.data.data.reports;
          this.currentPeriod = await res.data.data.reports[0];
        }
      } catch (e) {
        console.log(">>> getSavedTourismReports error", e.response.data);
      }
    },

    async getCountriesList(data = null) {
      let payload = !!data ? { continents: data } : null;
      let res;
      try {
        res = await getCountries(payload);
        if (res.status === 200) {
          this.countriesList = res.data.data.countries;
        }
      } catch (e) {
        console.log(">>> getCountriesList error", e);
      }
    },

    async getStatisticsTable() {
      let res;
      let payload = {
        ...this.getTableSortData,
        "page[size]": 300,
        quarter: this.currentPeriod.quarter,
        year: this.currentPeriod.year,
        type_of_tourism: this.getCurrentTypeTourism,
      };
      if (!!this.currentContinents?.length) {
        payload.continents = this.currentContinents.map((el) => el.id);
      }
      if (!!this.currentCountries?.length) {
        payload.countries = this.currentCountries.map((el) => el.id);
      }
      try {
        res = await statisticsTableTourism(payload);
        if (res.status === 200) {
          this.table = await res.data.data.table;
          await this.setWidgetsData(
            res.data.data.table.total_number_of_tourists_on_continents
          );
          if (!!this.currentContinents.length || !!this.currentCountries.length) {
            this.mapData = await res.data.data.table.items;
          } else {
            this.mapData = null;
          }
        }
      } catch (e) {
        console.log(">>> getStatisticsTable error", e.response.data);
      }
    },

    async setWidgetsData(data) {
      this.isWidget = !data.every((el) => el.number_of_tourists === 0);

      this.widgetsData = [
        {
          id: this.type,
          type: "doughnut",
          data: {
            labels: await data.map((el) => el.name),
            datasets: [
              {
                data: await data.map((el) => el.number_of_tourists),
                backgroundColor: this.getWidgetColors.background,
                hoverBackgroundColor: this.getWidgetColors.hover,
              },
            ],
          },
        },
      ];
    },

    tableSortHandler() {},
  },
};
</script>

<style lang="sass" scoped>
.statistic-type__filter-block
  display: flex
  margin-bottom: 10rem
  min-height: 70px
  @include xs
    flex-direction: column
    margin-bottom: 6rem
.statistic-type__filter-select
  width: 30%
  max-width: 350px
  @include s
    width: 50%
    max-width: 50%
  @include xs
    width: 100%
    max-width: 100%
  &:first-child
    margin-right: 60px
    @include xs
      margin-right: 0
      margin-bottom: 30px
.statistic-type__period
  display: flex
  align-items: flex-end
  margin-bottom: 7rem
  @include xs
    margin-bottom: 30px
    flex-direction: column
.statistic__filter-item
  width: 30%
  max-width: 350px
  @include s
    width: 50%
    max-width: 50%
  @include xs
    width: 100%
    max-width: 100%
  &:first-child
    margin-right: 60px
    @include xs
      margin-right: 0
      margin-bottom: 30px

.statistic__map
  width: 100%
  @include s
    margin-bottom: 0
  @include xs
    margin-bottom: 30px
.statistic__map-container
  width: 100%
  // height: 330px
  display: flex
  justify-content: center
  margin-bottom: 30px
  @include s
    margin-bottom: 0

.statistic__map-info
  display: flex
  align-content: center
  background: $black
  color: $white
  padding: 5px 10px
  font-size: 12px
  line-height: 12px
.statistic__map-info-item
  display: flex
  align-items: center
  margin: 0 7px 0 5px
  &:last-child
    margin-right: 0
.statistic__map-info-title
  align-self: center
.statistic__map-info-indicator
  display: inline-block
  width: 16px
  height: 16px
  margin: 0 5px
.statistic__map-info-indicator_from
  background-color: #b2d5ff
.statistic__map-info-indicator_to
  background-color: #0075ff

.statistic-table ::v-deep .statistic-table__footer-item:nth-child(3)
  text-align: right
  padding-right: 7px
  white-space: nowrap
  @include xs
    text-align: left
    padding: 10px

</style>
