<template>
  <div class="statistic-type statistic-type__wrapper">
    <StatisticsInfoBlock
      class="statistic__info-block--hotels-count"
      :title="$t('statistic.title_hotels_total')"
      :content="hotelsTotal"
    />

    <div class="statistic__star-category">
      <div class="statistic__star-category-item" v-for="(item) in hotelsByStarCategory" :key="item.star_category">
        <star-rating
          v-model="item.category"
          :active-on-click="false"
          :max-rating="item.category"
          :star-size="24"
          :fixed-points="1"
          :show-rating="false"
          :clearable="false"
          :padding="8"
          :border-width="2"
          :inline="true"
          :read-only="true"
          border-color="#6B6B6B"
          active-border-color="#FFB33B"
          active-color="#FFB33B"
          inactive-color="#fff"
        />
        <div class="statistic__star-category-item-text">{{ item.total }}</div>
      </div>
    </div>

    <h5 class="title-h5 mb-40">{{ $t('statistic.title_hotels_by_regions') }}</h5>

    <StatisticsTable
      :table-head="tableHead"
      :table="{items: hotelsByRegions}"
      class="statistic__table-item"
    />

    <div class="statistic__tour-operators-info">
      <div class="statistic__tour-operators-info-row">
        <StatisticsInfoBlock
          :title="$t('statistic.title_tour_operators_total')"
          :content="tourOperatorsTotal"
        />
      </div>

      <MapRegions
        class="statistic__tour-operators-info-map"
        :title="$t('statistic.title_tour_operators_by_regions')"
        :data="tourOperatorsByRegions"
      />
    </div>

    <div class="statistic__totals-row">
      <StatisticsInfoBlock
        :title="$t('statistic.title_subjects_total')"
        :content="totalSubjects"
      />
      <StatisticsInfoBlock
        :title="$t('statistic.title_tax_total')"
        :content="`${taxTotal} ${$t('main.finance.thousands')}`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatisticsTable from "@/elements/StatisticsTable.vue";
import StarRating from 'vue-star-rating';
import MapRegions from '@/elements/MapRegions.vue';
import StatisticsInfoBlock from '@/elements/StatisticsInfoBlock.vue';
import { pickRgbRange, numberFormat } from '@/library/helpers.js'

export default {
  components: {
    StatisticsInfoBlock,
    StatisticsTable,
    StarRating,
    MapRegions,
  },

  props: {
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tableHead: [
        'region_label',
        'star_category_5',
        'star_category_4',
        'star_category_3',
        'star_category_2',
        'star_category_1',
      ],
    };
  },

  async created() {
    await this.$store.dispatch('fetchHotelsByRegions');
    await this.$store.dispatch('fetchTourOperatorsByRegions');
    await this.$store.dispatch('fetchTaxRevenuesTotal');
  },

  watch: {
    '$route': {
      async handler(val) {
        await this.$store.dispatch('fetchHotelsByRegions');
        await this.$store.dispatch('fetchTourOperatorsByRegions');
        await this.$store.dispatch('fetchTaxRevenuesTotal');
      }
    },
  },

  computed: {
    ...mapGetters([
      'getHotelsByRegions',
      'getHotelsTotal',
      'getTourOperatorsByRegions',
      'getTourOperatorsTotal',
      'getTaxRevenuesTotal',
    ]),

    hotelsByRegions() {
      return this.getHotelsByRegions.map(row => {
        row.region_label = row.name;
        
        return row;
      });
    },

    hotelsByStarCategory() {
      const categories = [
        {
          category: 1,
          total: 0,
        },
        {
          category: 2,
          total: 0,
        },
        {
          category: 3,
          total: 0,
        },
        {
          category: 4,
          total: 0,
        },
        {
          category: 5,
          total: 0,
        },
      ];

      this.getHotelsByRegions.forEach(row => {
        categories.forEach(category => {
          category.total += row[`star_category_${category.category}`]
        })
      });

      return categories.reverse();
    },

    hotelsTotal() {
      return numberFormat(this.getHotelsTotal, 0, 3, ' ', '.');
    },

    tourOperatorsTotal() {
      return numberFormat(this.getTourOperatorsTotal, 0, 3, ' ', '.');
    },

    totalSubjects() {
      return numberFormat(parseInt(this.getHotelsTotal) + parseInt(this.getTourOperatorsTotal), 0, 3, ' ', '.');
    },

    taxTotal() {
      return numberFormat(parseInt(this.getTaxRevenuesTotal.total_sum / 1000), 2, 3, ' ', ',');
    },

    tourOperatorsByRegions() {
      return this.getTourOperatorsByRegions.map(row => {

        const ratio = (parseInt(row.tour_operators_total) / (parseInt(this.getHotelsTotal) + parseInt(this.getTourOperatorsTotal))) * 100
        const percentOfTotal = ratio < 10 ? ratio : Math.round(ratio / 5) * 5

        const color = pickRgbRange(percentOfTotal, [
                        {color: [235,235,235,1], position: 0},
                        {color: [137,180,251,1], position: 1},
                        {color: [109,159,250,1], position: 45},
                        {color: [20,89,147,1], position: 100},
                      ])

        return {
            katottg: row.katottg,
            fill: `rgb(${color.join(',')})`,
            content: {
              text: row.tour_operators_total,
            }
          }
      })
    },
  },

  methods: {

  },
};
</script>

<style lang="sass" scoped>
.statistic

  &__filter-item
    width: 30%
    max-width: 350px
    
    @include s
      width: 50%
      max-width: 50%
    @include xs
      width: 100%
      max-width: 100%
    &:first-child
      @include xs
        margin-right: 0
        margin-bottom: 30px

  &__info
    
    &-title
      @include m
        white-space: normal
    &-block
      ::v-deep .title-h3
        @include m
          white-space: nowrap
          font-size: 2.8rem
          line-height: 5rem

  &__info-block
    &--hotels-count,
    &--tour-operators-count
      margin-top: 40px

  &__star-category
    display: flex
    gap: 0 48px
    margin: 40px 0 64px 0
    max-width: 1000px

    @include xs
      flex-wrap: wrap
      flex-direction: column

    ::v-deep .vue-star-rating-star
      &:last-child
        margin-right: 0 !important

    &-item
      text-align: center
      width: 20%

      @include xs
        width: 100%
        text-align: left
        display: flex
        align-items: center
        margin-bottom: 16px

        &:last-child
          margin-bottom: 0

      &-text
        font-size: 32px
        line-height: 40px
        margin-top: 8px

        @include xs
          margin-top: 0
          margin-left: 16px

  &__table-item
    width: 100%
    max-width: initial

    ::v-deep 
      .simple-pagination__wrapper
        margin-top: 1rem

      .statistic-table__greed
        grid-template-columns: minmax(15rem, 10fr) minmax(10rem, 6fr) minmax(10rem, 6fr) minmax(10rem, 6fr) minmax(10rem, 6fr) minmax(10rem, 3fr)

      .statistic-table__content-item:not(.statistic-table__content-item:first-child, .statistic-table__content-item:last-child, .statistic-table__growth-item)
        padding: 0 15px
        text-align: center

      .statistic-table__header-item
        text-align: center

        &:first-child
          text-align: left

      .statistic-table__content-item
        padding: 0 10px
        
        &:last-child
          padding-right: 25px
          text-align: center

      .statistic-table__header-ico,
      .statistic-table__footer
        display: none

      @media screen and (max-width: 767px)
        .statistic-table__content-item,
        .statistic-table__content-item:not(.statistic-table__content-item:first-child, .statistic-table__content-item:last-child, .statistic-table__growth-item)
          padding: 0

        .statistic-table__greed
          flex-direction: column

        .statistic-table__wrapper
          display: flex
          flex-wrap: wrap

        .statistic-table__greed
          width: 50%
          padding-left: 0
          padding-right: 0

        :nth-last-child(1 of .statistic-table__content)
          width: 100%
        
        .statistic-table__content-item
          width: 100%
          flex-wrap: nowrap

        .statistic-table__header-title
          margin-bottom: 8px
          width: 120px
          text-align: left
          white-space: nowrap

      @include xs
        .statistic-table__greed
          width: 100%

  &__tour-operators
    &-info
      display: flex
      align-items: flex-start
      gap: 0 100px

      @include m
        gap: 0 30px

      @include xs
          flex-wrap: wrap

      &-row
        width: 25%
        margin-top: 72px

        @include m
          width: 45%

        @include xs
          width: 100%
          margin-top: 64px

        .statistic__filter-item
          width: 100%
          white-space: nowrap

          &:first-child
            margin-top: 0

          @include s
            width: 100%
            max-width: initial

          @include xs
            margin-top: 24px
            margin-bottom: 0

      &-map
        width: 75%

        @include m
          width: 60%

        @include xs
          width: 100%
          text-align: left

  &__totals
    &-row
      display: flex
      justify-content: space-between
      gap: 0 100px
      margin-top: 72px

      @include s
        gap: 0 40px
        margin-top: 24px

      @include xs
        flex-direction: column
        gap: 0

      .statistic__info-block
        max-width: initial
        width: 100%
        display: flex
        flex-direction: column
        justify-content: space-between

        @include xs
          margin-top: 2rem
          margin-bottom: 0
</style>
