<template>
  <div class="chart-item">
    <canvas :id="chartId"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'WidgetChart',

  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    chartType: {
      type: String,
      default: '',
    },
    chartOptions: {
      type: [Object],
      default: null,
    },
    chartId: {
      type: String,
    },
    chartWidth: {
      type: [Number, String],
    },
    isWidget: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      curData: {
        type: '',
        data: null,
        options: null,
      },
    };
  },

  mounted() {
    this.curData.type = this.chartType;
    this.curData.data = this.chartData;
    this.curData.options = this.chartOptions;

    this.getChartInstance(document.getElementById(this.chartId), {
      type: this.chartType,
      data: this.chartData,
      options: this.chartOptions,
    });

    this.$emit('reloaded');
  },

  methods: {
    getChartInstance(el, data) {
      return new Chart(el, data);
    },
  },
};
</script>
<style lang="sass" scoped>
.chart-item
  max-width: 350px
  margin: auto
</style>
